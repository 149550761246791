import {
  init as initChameleon,
  identify as register,
  track as trackEvent,
} from '@chamaeleonidae/chmln'

declare global {
  interface Window {
    chmln?: any
  }
}

export const init = () => {
  initChameleon(process.env.REACT_APP_CHAMELEON_KEY, {
    fastUrl: 'https://fast.chameleon.io/',
  })
}

export const watchEvent = (
  onEventListener: (eventName: string, options: Record<string, string>) => void,
  eventName = 'chmln:event'
) => {
  if (!window.chmln) {
    throw new Error('Chameleon is not initialized. Please call init first')
  }

  window.chmln.on(eventName, onEventListener)
}

export const track = (eventName: string) => {
  trackEvent(eventName)
}

export const identify = (
  userId: string,
  additionalData: Record<string, string | boolean | undefined>
) => {
  if (navigator.webdriver) {
    return
  }

  register(userId, additionalData)
}
