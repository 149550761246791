import { environment } from '@chilipiper/config'
import { useEffect, useState, useCallback } from 'react'
import LogRocket from 'logrocket'
import { useUserInteraction } from './useUserInteraction'
import { shouldSampleSession } from './shouldSampleSession'

export const LOG_ROCKET_APP_ID = 'cydyi9/customer-love'

export const DEFAULT_OPTIONS: Required<Options> = {
  appId: LOG_ROCKET_APP_ID,
  samplingRate: 1, // Default to 100% sampling
  delayInitOnInteraction: false, // Default to immediate initialization
  currentEnvironment: environment.deploy,
  allowedEnvironments: ['canary', 'production'],
}

type Options = {
  appId?: string
  samplingRate?: number
  currentEnvironment?: string
  allowedEnvironments?: string[]
  delayInitOnInteraction?: boolean
}

type User = { email?: string; id: string; tenantId?: string } & Record<
  string,
  string | number | boolean
>

export const useLogRocket = (user?: User, opts: Options = DEFAULT_OPTIONS) => {
  const { allowedEnvironments, currentEnvironment, appId, samplingRate, delayInitOnInteraction } = {
    ...DEFAULT_OPTIONS,
    ...opts,
  }
  const [isInitialized, setIsInitialized] = useState(false)
  const isSampled = shouldSampleSession(user?.id, samplingRate)
  const isEnabled = allowedEnvironments.includes(currentEnvironment)

  const initializeLogRocket = useCallback(() => {
    if (isEnabled && isSampled && !isInitialized) {
      LogRocket.init(appId)
      if (user?.id) {
        logRocketIdentify(user)
      }
      setIsInitialized(true)
    }
  }, [isEnabled, isSampled, isInitialized, appId, user])

  useEffect(() => {
    if (!delayInitOnInteraction) {
      initializeLogRocket()
    }
  }, [delayInitOnInteraction, initializeLogRocket])

  useUserInteraction(() => {
    initializeLogRocket()
  })
}

export const logRocketIdentify = (user: User) => {
  const props: Record<string, string | number | boolean> = {}

  if (user.email) {
    props.email = user.email
  }

  if (user.tenantId) {
    props.tenantId = user.tenantId
  }

  LogRocket.identify(user.id, props)
}

export const logRocketTrack = (
  name: string,
  props?: Record<string, string | number | boolean | string[] | number[] | boolean[] | undefined>
) => {
  LogRocket.track(name, props)
}
