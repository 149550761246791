import { useCallback, useEffect } from 'react'
export type InteractionHandler = () => void

/**
 * Custom hook to handle user interactions such as click, keydown, and scroll.
 *
 * @param onInteract - Callback to execute upon user interaction.
 */
export const useUserInteraction = (onInteract: InteractionHandler): void => {
  const handleInteraction = useCallback(() => {
    onInteract()
  }, [onInteract])

  useEffect(() => {
    // Add event listeners for user interactions
    window.addEventListener('click', handleInteraction)
    window.addEventListener('keydown', handleInteraction)

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('click', handleInteraction)
      window.removeEventListener('keydown', handleInteraction)
    }
  }, [handleInteraction, onInteract])
}
